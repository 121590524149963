import React, { useState, useRef} from 'react'
import axios from 'axios'

import Illustrations from '../Illustrations/Illustrations'

const EMAIL_KEY = process.env.EMAIL_KEY || 'kieron123'

export default function ContactForm ( props ) {

	const { subject, setSubject, enquiryPlaceholder, isBeautyPage } = props

	const form = useRef();
	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ enquiry, setEnquiry ] = useState('')
	const [ formSuccess, setFormSuccess ] = useState(false)
	const [ error, setError ] = useState('')

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		form.current.reportValidity();

		const data = {
			name,
			email,
			subject,
			enquiry,
			isBeautyPage,
			EMAIL_KEY
		}

		try {
			const result = await axios.post('/api/email', data);

			if ( result.status === 200 ) {
				setFormSuccess( true )
			}
		} catch( error ) {
			console.log( error )
			setError('Error')
		}
	}

	return (
		<>
			{ !formSuccess ? (
				<form className="form" onSubmit={ handleSubmit } ref={ form }>
					<div className="form__input-container">
						<label className="form__label" htmlFor="name">Name</label>
						<input
							onChange={ e => { setName(e.target.value) }}
							value={ name }
							className="form__input" id="name" type="text" placeholder="Name" required />
					</div>

					<div className="form__input-container">
						<label className="form__label" htmlFor="email">Email</label>
						<input
							onChange={ e => { setEmail(e.target.value) }}
							value={ email }
							className="form__input" id="email" type="email" placeholder="Email" required />
					</div>

					<div className="form__input-container">
						<label className="form__label" htmlFor="subject">Subject</label>
						<input
							onChange={ e => { setSubject(e.target.value) }}
							value={ subject }
							className="form__input" id="subject" type="text" placeholder="Subject" required />
					</div>

					<div className="form__input-container">
						<label className="form__label" htmlFor="enquiry">Enquiry</label>
						<textarea
							onChange={ e => { setEnquiry(e.target.value) }}
							value={ enquiry }
							className="form__input" id="enquiry" type="text" placeholder={ enquiryPlaceholder } rows="5" required />
					</div>

					<input type="hidden" id="" name="" value=""></input>

					{ ( error.length > 1 ) && (
						<div className="form__error-container">
							<span className="form__error-message">Sorry, we have been unable to send your query.</span>
							<span className="form__error-message">Please try again later.</span>
						</div>
					)}

					<div className="form__button-container">
						<button className="form__submit button button--primary button--full-width" type="submit">Submit</button>
					</div>
				</form>
			) : (
				<p className="form-page__success">
					{ isBeautyPage ? (
						<>Thank you for contacting dream on beauty. We will try and respond to your query as soon as possible!</>
					) : (
						<>Thank you for contacting dream on. We will try and respond to your query as soon as possible!</>
					) }
					<Illustrations class="form-page__success-image" illustration={ 'Family' } />
				</p>
			) }
		</>
	)
}