import React, { useEffect, useState } from 'react'

import SEO from '../components/SEO/Seo'
import ContactForm from '../components/ContactForm/ContactForm'

export default ({ location }) => {
	const [ subject, setSubject ] = useState('')
	const [ enquiryPlaceholder, setEnquiryPlaceholder ] = useState('Enquiry')

	useEffect(() => {
		if ( location && location.state && location.state.booking ) {
			setSubject( `Booking Request: ${ location.state.booking }` )
			setEnquiryPlaceholder( 'Please let us know when you are looking to book here:' )
		}
	}, [ location ] )

	return(
		<>
			<SEO
				title="Contact Us"
				description="Get in touch with dream on using the form on the contact us page."
				pathname={ location.pathname } />

			<div className= "wrapper">
				<div className="form-page">
					<div className="form-page__header">
						<h1 className="form-page__heading">Contact Us</h1>
						<p className="form-page__info">
							Please fill out the following form if you have any queries or questions.
						</p>
					</div>

					<ContactForm subject={ subject } setSubject={ setSubject } enquiryPlaceholder={ enquiryPlaceholder } isBeautyPage={ false } />
				</div>
			</div>
		</>
	)
}